<template>
  <Layout tituloPagina="Calendario | Inicio">
    <div class="row">
      <div class="col-lg-5 col-md-7 col-sm-8 col-12">
        <cmp-secciones seccion="calendario"></cmp-secciones>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="app-calendar">
              <FullCalendar
                ref="fullCalendar"
                :options="calendarOptions"
              ></FullCalendar>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mdl-ticket
      ref="mdlTicket"
      @ticket:actualizado="recargarEventos()"
    ></mdl-ticket>
    <mdl-evento
      ref="mdlEvento"
      @evento:actualizado="recargarEventos()"
    ></mdl-evento>
    <mdl-instalacion
      ref="mdlInstalacion"
      @instalacion:actualizado="recargarEventos()"
    ></mdl-instalacion>
    <mdl-ajustes-calendario
      ref="mdlAjustesCalendar"
      @ajustes-calendario:actualizar="cargarCalendarios()"
    ></mdl-ajustes-calendario>
    <mdl-seleccionar-calendario
      ref="mdlSeleccionarCalendario"
      @evento:guardado="recargarEventos()"
    ></mdl-seleccionar-calendario>
  </Layout>
</template>

<script>
import interactionPlugin from "@fullcalendar/interaction"
import bootstrapPlugin from "@fullcalendar/bootstrap"
import esLocales from "@fullcalendar/core/locales/es"
import timeGridPlugin from "@fullcalendar/timegrid"
import dayGridPlugin from "@fullcalendar/daygrid"
import FullCalendar from "@fullcalendar/vue3"
import listPlugin from "@fullcalendar/list"

import Layout from "@/views/layouts/main"
import CmpSecciones from "./CmpSecciones.vue"

import MdlSeleccionarCalendario from "./MdlSeleccionarCalendario.vue"
import MdlAjustesCalendario from "./MdlAjustesCalendario.vue"
import MdlInstalacion from "./MdlInstalacion.vue"
import MdlEvento from "./MdlEvento.vue"
import MdlTicket from "./MdlTicket.vue"

import EventoCalendarioSrv from "@/services/EventoCalendarioSrv"
import CalendarioSrv from "@/services/CalendarioSrv.js"
import SistemaSrv from "@/services/SistemaSrv.js"
import moment from "moment"

export default {
  name: "Calendario",
  components: {
    Layout,
    CmpSecciones,
    FullCalendar,
    MdlAjustesCalendario,
    MdlSeleccionarCalendario,
    MdlEvento,
    MdlTicket,
    MdlInstalacion
  },
  data() {
    return {
      calendarOptions: {
        headerToolbar: {
          left: "prev,next today calendario",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        customButtons: {
          calendario: {
            text: "Calendarios visibles",
            click: this.mostrarModalCalendario
          }
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin
        ],
        // initialDate: "2014-02-01",
        initialView: "dayGridMonth",
        themeSystem: "bootstrap",
        events: this.refrescarEventos,
        businessHours: {
          startTime: "09:00", // hora inicial
          endTime: "18:00", // hora final
          daysOfWeek: [1, 2, 3, 4, 5] // dias de semana, 0=Domingo
        },
        eventDisplay: "block",
        allDaySlot: false,
        editable: true,
        droppable: true,
        eventResizableFromStart: false,
        dateClick: this.manejarEventoClick,
        eventClick: this.manejarEvento,
        eventDrop: this.manejoEventoDrop,
        eventResize: this.manejarEventoRedimencionar,
        weekends: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        locale: esLocales
      },
      calendarios: [],
      eventos: []
    }
  },
  created: function() {
    var self = this
    
    self.cargarCalendarios()
    self.cargarAjustes()
  },
  mounted() {
    var boton = document.querySelector('[title="Calendarios visibles"]');
    boton.classList.remove("btn-primary");
    boton.classList.add("btn-outline-primary");
  },

  computed: {
    idsCalendarios: function() {
      var self = this

      let idsCal = self.calendarios.filter(cal => {
        if(cal.visible == true) return cal.id
      })

      idsCal = idsCal.map(id => id.id)

      return idsCal
    }
  },
  methods: {
    cargarAjustes() {
      let self = this
      let ajustesSolicitados = ["hora_entrada","hora_salida","dias_laborales"]

      SistemaSrv.ajustes(ajustesSolicitados).then(response => {
        let ajustes = response.data
        
        ajustes.dias_laborales = JSON.parse(ajustes.dias_laborales)
        ajustes.dias_laborales = ajustes.dias_laborales.map(dia => dia.id)
        
        self.calendarOptions.businessHours.startTime = ajustes.hora_entrada
        self.calendarOptions.businessHours.endTime = ajustes.hora_salida
        self.calendarOptions.businessHours.daysOfWeek = ajustes.dias_laborales
      })
    },
    cargarCalendarios:function() {
      var self = this, params = { sin_paginacion: true }

      CalendarioSrv.calendariosJSON(params).then(response => {
        var calendarios = response.data

        calendarios.forEach(cal => {
          if(cal.usuario_ajustes_calendario){
            cal.color = cal.usuario_ajustes_calendario.color
            cal.visible = cal.usuario_ajustes_calendario.visible
          }
        })

        self.calendarios = calendarios
        self.recargarEventos()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se pudo cargar los calendarios"
        }
        iu.msg.error(mensaje)
        console.log(error)
        return []
      })
    },
    colorIdealTexto: function(hexColor) {
      let limite = 105
      let componentes = {
        r: parseInt( hexColor.substring(1,3), 16),
        g: parseInt( hexColor.substring(3,5), 16),
        b: parseInt( hexColor.substring(5,7), 16)
      }

      let bgDelta = (componentes.r * 0.299) + (componentes.g * 0.587) + (componentes.b * 0.114);
      
      return ((255 - bgDelta) < limite) ? "#000000" : "#ffffff";
    },
    manejarEvento: function(event) {
      var self = this

      let evento = self.eventos.find(e => {
        return e.id == event.event.id
      })

      if(evento.id_instalacion !== null) return self.$refs.mdlInstalacion.mostrar(event.event.id, evento.id_instalacion)
      if(evento.id_soporte !== null) return self.$refs.mdlTicket.mostrar(event.event.id, evento.id_soporte)

      self.$refs.mdlEvento.mostrar(event.event.id)
    },
    manejoEventoDrop: function(info) {
      var self = this 

      let evento = self.eventos.find(e => {
        return e.id == info.event.id
      })

      let calendarApi = self.$refs.fullCalendar.getApi()

      let datos = {
        id: info.event.id,
        fecha_programada: moment(info.event.start).format("YYYY-MM-DD HH:mm:ss"),
      }

      if(evento.id_instalacion !== null) Object.assign(datos, {id_instalacion: evento.id_instalacion})
      if(evento.id_soporte !== null) Object.assign(datos, {id_soporte: evento.id_soporte})

      EventoCalendarioSrv.actualizarFT(datos).then(response => {
        iu.msg.success("Se actualizó correctamente la fecha programada del evento: "+info.event.title)
        calendarApi.refetchEvents()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se actualizo el evento: "+info.event.title
        }
        iu.msg.error(mensaje)
        console.log(error)
        calendarApi.refetchEvents()
      })
    },
    manejarEventoRedimencionar: function(info) {
      var self = this 

      let evento = self.eventos.find(e => {
        return e.id == info.event.id
      })

      let calendarApi = self.$refs.fullCalendar.getApi()

      let fecha_inicial = moment(info.event.start)
      let fecha_final = moment(info.event.end)
      let diferencia_segundos = fecha_final.diff(fecha_inicial,"seconds")

      let datos = {
        id: info.event.id,
        tiempo_estimado: diferencia_segundos
      }

      if(evento.id_instalacion !== null) Object.assign(datos, {id_instalacion: evento.id_instalacion})
      if(evento.id_soporte !== null) Object.assign(datos, {id_soporte: evento.id_soporte})

      EventoCalendarioSrv.actualizarFT(datos).then(response => {
        iu.msg.success("Se actualizó correctamente el tiempo estimado del evento: "+info.event.title)
        calendarApi.refetchEvents()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se actualizo el evento: "+info.event.title
        }
        iu.msg.error(mensaje)
        console.log(error)
        calendarApi.refetchEvents()
      })
    },
    manejarEventoClick: function(event){
      var self = this, array = moment(event.date).format("YYYY-MM-DD HH:mm:ss").split(" "),
      fecha= array[0], hora = moment(array[1], "HH:mm:ss").format("HH:mm:ss")

      if(hora == "00:00:00") hora = moment().format("HH:mm:ss")

      self.$refs.mdlSeleccionarCalendario.mostrar(fecha, hora)
    },
    mostrarModalCalendario: function() {
      var self = this
      self.$refs.mdlAjustesCalendar.mostrar()
    },
    recargarEventos() {
      var self = this 

      let calendarApi = self.$refs.fullCalendar.getApi()
      calendarApi.refetchEvents()
    },
    refrescarEventos(fetchInfo, successCallback, failureCallback) {
      var self = this

      let params = {
        fecha_inicial: moment(fetchInfo.start).format("YYYY-MM-DD"),
        fecha_final: moment(fetchInfo.end).format("YYYY-MM-DD"),
        sin_paginacion: true,
        con_ids_calendarios: true,
        ids_calendarios: self.idsCalendarios,
        sin_cancelados: true
      }
      self.eventos = []
      EventoCalendarioSrv.eventosJSON(params).then(response => {
        self.eventos = response.data
        let eventos = response.data,
            datos = []

        eventos.forEach(t => {
          let calendario = t.calendario
          let usuario_ajuste = calendario.usuario_ajuste

          let editable = t.estado != 3 && t.estado != 4 ? true : false

          let data = {
            id: t.id,
            title: t.titulo,
            start: t.fecha_programada,
            end: moment(t.fecha_programada).add(t.tiempo_estimado ? t.tiempo_estimado : 3600,"seconds").format("YYYY-MM-DD HH:mm:ss"),
            color: usuario_ajuste ? usuario_ajuste.color : calendario.color,
            textColor: self.colorIdealTexto(usuario_ajuste ? usuario_ajuste.color : calendario.color),
            allDay: false,
            editable: editable
          }

          datos.push(data)
        });

        successCallback(datos)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se pudieron cargar los eventos"
        }
        iu.msg.error(mensaje)
        failureCallback(error)
      })
    }
  }
}
</script>

<style>
.fc-nonbusiness {
  background: #90EE90
}
</style>