<template>
  <div class="modal fade" ref="mdlAjustesCalendario" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header bg-success">
          <h4 class="modal-title text-white">
            Calendarios visibles
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row align-items-center border-bottom" v-for="calendario,index in calendarios" :key="calendario.id">
            <div class="col-md-6 col-6">
              <strong class="font-size-15">
                {{calendario.nombre}} {{ calendario.generico ? '*': '' }}
              </strong>
            </div>
            <div class="col-md-6 col-6 text-right">
              <div class="form-check form-switch form-switch-lg d-inline-block">
                <input
                  :checked="calendario.visible"
                  @change="
                    calendario.visible = !calendario.visible,
                    actualizarAjustes(calendario.id, index, 'visible')
                  "
                  class="form-check-input"
                  type="checkbox"
                  :id="'switch-'+calendario.nombre"
                />
                <label
                  class="form-check-label"
                  :for="'switch-'+calendario.nombre"
                ></label>
              </div>
              <input
                class="form-control form-control-color d-inline-block"
                type="color"
                v-model="calendario.color"
                @change="actualizarAjustes(calendario.id, index, 'color')"
              />
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-6">
              <button class="btn btn-light" @click="ajustes()">
                <i class="mdi mdi-cog-outline"></i>
                Ajustes
              </button>
            </div>
            <div class="col-6 text-right">
              <button
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                <i class="mdi mdi-close"></i>
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalendarioSrv from "@/services/CalendarioSrv.js"
import Modal from 'bootstrap/js/dist/modal';
export default {
  name: 'MdlAjustesCalendario',
  data() {
    return {
      calendarios: [],
    }
  },
  methods: {
    actualizarAjustes: function(idCalendario, index, tipo = 'color') {
      var self = this,
          calendario = self.calendarios[index]
      
      let datos = {
        color: calendario.color,
        visible: calendario.visible,
        id_calendario: idCalendario,
      }

      CalendarioSrv.actualizarAjustes(idCalendario, datos).then(response => {
        iu.msg.success('Se actualizo correctamente el '+tipo+' del calendario')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar el '+tipo+' del calendario'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.refrecarCalendario()
      })
    },

    refrecarCalendario() {
      var self = this
      self.$emit('ajustes-calendario:actualizar')
    },
    ajustes() {
      var self = this,
        modal = Modal.getInstance(self.$refs.mdlAjustesCalendario)

      modal.hide()
      self.$router.push({ name: 'ajustesCalendario' })
    },

    mostrar() {
      var self = this

      self.cargarCalendarios()
      var modal = new Modal(self.$refs.mdlAjustesCalendario)
      modal.show()
    },

    cargarCalendarios:function() {
      var self = this, params = { sin_paginacion: true }

      CalendarioSrv.calendariosJSON(params).then(response => {
        var calendarios = response.data

        calendarios.forEach(cal => {
          if(cal.usuario_ajustes_calendario){
            cal.color = cal.usuario_ajustes_calendario.color
            cal.visible = cal.usuario_ajustes_calendario.visible
          }
        })

        self.calendarios = calendarios
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar los calendarios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

</style>
